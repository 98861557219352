const LANG_PL = "pl";
const LANG_EN = "en";

module.exports = {
    defaultLanguage: LANG_PL,
    languages: [LANG_PL, LANG_EN],
    postsPerPage: 9,
    siteUrl: 'https://databout.pl',
    formsubmitId: "310ce0e1-7861-4390-948c-e6f370e4e30a"
}

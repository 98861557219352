import * as React from 'react';
import { OptionBar } from './SearchBar';
import styled from 'styled-components';
import { useState } from 'react';


type PriceButtonProps = {
    products: string[];
    handleChangeCard?: (option: string) => void;
}

const StyledButton = styled.button`
    width: 150px;
    height: 41px;
    font-size: 18px;
    color: ${({ theme }) => theme.blueColor};
    font-family: Museo;
    font-weight: 400;
    cursor: pointer;

    &:nth-of-type(1) {
        border-radius: 8px 0px 0px 8px;
        border: 2px solid ${({ theme }) => theme.blueColor};
    }
    &:nth-of-type(2) {
        border: none;
        border-top: 2px solid ${({ theme }) => theme.blueColor};
        border-bottom: 2px solid ${({ theme }) => theme.blueColor};
    }

    &:nth-last-of-type(1) {
        border-radius: 0px 8px 8px 0px;
        border: 2px solid ${({ theme }) => theme.blueColor};
    }

    &.active {
        color: ${({ theme }) => theme.whiteColor};
        background-color: ${({ theme }) => theme.blueColor};
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: none;
    }
`;

const StyledOptionBar = styled(OptionBar)`
    padding-bottom: 120px;
`;


export const PriceButton: React.FC<PriceButtonProps> = ({products, handleChangeCard}) => {
    const [selectedProduct, setSelectedProduct] = useState(products[0]);

    const handleGetOption = (product) => {
        setSelectedProduct(product);
        handleChangeCard && handleChangeCard(product);
    }

    const productButtons = products.map(product => 
        <StyledButton 
            key={product} 
            className={selectedProduct === product ? "active" : ""} 
            onClick={()=> handleGetOption(product)}>{product}
        </StyledButton>
    )

    return ( 
        <StyledOptionBar>
            {productButtons}
        </StyledOptionBar>

    );
}
 

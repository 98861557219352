const defaultLanguage = require("./site-config").defaultLanguage;

function getId(post) {
    const language = post.language.slug;
    const id = language === defaultLanguage ? post.databaseId : post.translations.find(p => p.language.slug === defaultLanguage).databaseId;
    return id;
}

function getContentId(post) {
    return post.databaseId;
}


function getModifiedGmt(post) {
        return post.modifiedGmt;
    }

function makePostUrl(post, path, language = undefined) {
    if(!language && post.language.slug === defaultLanguage) return `/${path}/${getId(post)}`
    return `/${language ?? post.language.slug}/${path}/${getId(post)}`
}

module.exports = {
    getId,
    getContentId,
    makePostUrl,
    getModifiedGmt
}

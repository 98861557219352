import * as React from 'react';
import styled from 'styled-components';
import { makePostUrl } from '../../../wp-post-utils';
import { CareerProps } from '../../pages/kariera';
import CareerCard from '../molecules/CareerCard';


export const CardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

 
const CareerCardsGallery: React.FC<CareerProps> = ({data}) => {

    const cards = data.allWpPost.nodes.map(card => {
        const {careerTitle, officeLocation, expirence, formEmployment, fullTime, salary} = card.career;
        return ( 
            <CareerCard 
                key={card.uri}
                careerTitle={careerTitle}
                officeLocation={officeLocation}
                expirence={expirence}
                formEmployment={formEmployment}
                fullTime={fullTime}
                salary={salary}
                link={makePostUrl(card, "kariera")}
            />
        )
     })

    return (            
        <CardsWrapper>
          {cards}
        </CardsWrapper>
    );
}
 
export default CareerCardsGallery;
import * as React from 'react';
import styled from 'styled-components';
import waves from "../../images/waves-second.jpg";
import Dropdown from '../atoms/Dropdown';
import { PriceButton } from '../atoms/PriceButton';

type HeaderNewsPriceCarrerProps = {
    title: string,
    description: string;
    isButton: boolean;
    isWhiteBackground: boolean;
    handleChangeCard?: (option: string) => void;
    image: string;
}

const Background = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url(${waves});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    @media (min-width: 992px) and (max-width: 1600px) {
        height: 92vh;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        background-position: top right;
        background-size: 250%;
        height: 77vh;
    }
`;

const StyledImage = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 258px;

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
    }
`;

const Title = styled.h1`
    margin: 13px 0;
    color: ${({ theme }) => theme.primaryColor};

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: 0 0 40px;
    }
`;

const DescriptionContent = styled.p`
    font-size: 24px;
  

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: 0 0 25px;
        font-size: 23px;
    }
`;

const Desktop = styled.div`
    text-align: center;
    
    @media (min-width: 1px) and (max-width: 767.98px) {
        display: none;
    }
`;

const Mobile = styled.div`
    display: none;

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: ${({ theme }) => theme.paddingPhone}; 
    }
`;

const DropdownWrapper = styled.div`
    width: 100%;
`;

const products = ['LIS', 'Harmony'];

const HeaderNewsPriceCarrer: React.FC<HeaderNewsPriceCarrerProps> = ( {title, description, isButton, isWhiteBackground, handleChangeCard, image} ) => {
    return ( 
        <>
            <Background>
               <StyledImage src={image} alt={title} loading="eager"/>
                <Desktop>
                    <Title>{title}</Title>
                    <DescriptionContent>
                        {description}
                        {products.length === 1 && " Databout.Tools"}
                    </DescriptionContent>
                    { (isButton && products.length > 1) && <PriceButton products={products} handleChangeCard={handleChangeCard}/>}
                </Desktop>
            </Background>
            <Mobile>
                <Title>{title}</Title>
                <DescriptionContent>
                    {description}
                    {products.length === 1 && " Databout.Tools"}
                </DescriptionContent>
               {
                (isButton && products.length > 1) && 
                    <DropdownWrapper>
                        {isButton && <Dropdown options={products} isWhiteBackground={isWhiteBackground} handleDropdown={handleChangeCard}/>}
                    </DropdownWrapper>
                }
            </Mobile>
        </>
     );
}
 
export default HeaderNewsPriceCarrer;
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LocalizationType } from '../../AllLocalizations';

type DropdownProps = {
    options: string[];
    isWhiteBackground?: boolean;
    handleDropdown?: (option: string) => void;
    activeOption?: string | LocalizationType;
}

type DropdownBoxProps = {
    isDropdownOpen: boolean;
    isWhiteBackground: boolean;
}

const DropdownBox = styled.div<Pick<DropdownBoxProps, "isDropdownOpen" | "isWhiteBackground">>`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    max-width: 395px;
    width: 100%;
    max-height: ${({isDropdownOpen}) => isDropdownOpen ? "600px" : "53px"};
	background-color: ${({isWhiteBackground}) => isWhiteBackground ? ({ theme }) => theme.whiteColor : "transparent"};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.lightGreyColor};
    font-size: 18px;
    font-family: Museo Sans;
    font-weight: 300;
    overflow: hidden;
    color: ${({isWhiteBackground}) => isWhiteBackground ? ({ theme }) => theme.blackColor : ({ theme }) => theme.greyColor};
    transition: max-height 0.2s ease-in-out;

`;

const DropdownWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    z-index: 2;
`

const DropdownLabel = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
    height: 51px;
    align-items: center;
    cursor: pointer;
`;


const Arrow = styled.span`
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    position: relative;
`;

const StyledSpan = styled.span`
    top: 0.5rem;
    position: absolute;
    width: .75rem;
    height: .1rem;
    background-color: ${({ theme }) => theme.blueColor};
    display: inline-block;
    transition: all .2s ease;

    &:first-of-type {
        left: 0;
        transform: rotate(45deg);
    }

    &:last-of-type {
        right: 0;
        transform: rotate(-45deg);
      }

    &.active:first-of-type {
        transform: rotate(-45deg);
    }

    &.active:last-of-type {
        transform: rotate(45deg);
    }
`;

const DropdownItem = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    height: 51px;
    padding: 0 16px;
    text-align: left;
    color: ${({ theme }) => theme.blackColor};
    cursor: pointer;
    margin-left: 0;

    &:hover {
        color: ${({ theme }) => theme.primaryColor};
        background-color: ${({ theme }) => theme.whiteHoverColor};
    }
`;

 
const Dropdown: React.FC<DropdownProps> = ({options, isWhiteBackground = false, handleDropdown, activeOption}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [currentChoice, setCurrentChoice] = useState<string | LocalizationType>(options[0]);

    const handleGetOption = (option) => {
        setCurrentChoice(option);
        setIsDropdownOpen(false);
        handleDropdown && handleDropdown(option);
    }

    useEffect(() => {
        setCurrentChoice(activeOption || currentChoice);
    }, [activeOption])

    const optionsItems = options.map(option => <DropdownItem key={option} onClick={() => handleGetOption(option)}>{option}</DropdownItem>)

    return ( 
        <DropdownWrapper>
            <DropdownBox isDropdownOpen={isDropdownOpen} isWhiteBackground={isWhiteBackground}>
                <DropdownLabel onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                    <span>{currentChoice}</span>
                    <Arrow>
                        <StyledSpan className={isDropdownOpen && "active"}/>
                        <StyledSpan className={isDropdownOpen && "active"}/>
                    </Arrow>
                </DropdownLabel>
                {optionsItems}
            </DropdownBox>
        </DropdownWrapper>
     );
}
 
export default Dropdown;
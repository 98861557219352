import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ArrowLink from '../atoms/ArrowLink';

type CareerCardProps = {
    careerTitle: string;
    officeLocation: string;
    expirence: string;
    formEmployment: string;
    fullTime: string;
    link: string;
    salary?: string;
}

const Card = styled.div`
    position: relative;
    width: 438px;
    height: 360px;
    background-color: ${({ theme }) => theme.whiteColor};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 16px 16px 16px 16px;
    margin: -130px 23px 0;
    margin-bottom: 200px;
    padding: 42px 21px;

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: 30px 20px 30px 16px;
        height: auto;
        padding-bottom: 100px;
    }
`;


const Title = styled.h2`
    margin: 0;
    margin-bottom: 30px;
    font-size: 19px;
    line-height: 1.2;
    height: 46px;

    @media (min-width: 1px) and (max-width: 767.98px) {
        height: auto;
        font-size: 18px;
    }
`;


const InfoText = styled.p`
    margin: 0;
    font-size: 15px;
    padding-right: 15px;

    b {
        font-weight: 600;
        font-family: Museo;
    }
`;

const StyledArrowLink = styled.div`
    position: absolute;
    bottom: 35px;
    left: 21px;
`;


 
const CareerCard: React.FC<CareerCardProps> = ({
    careerTitle,
    officeLocation,
    expirence,
    formEmployment,
    fullTime,
    salary,
    link }) => {

    const {t} = useTranslation();
    
    return ( 
        <Card>
            <Title>{careerTitle}</Title>
            <InfoText>{t('career.localization')}: <b>{officeLocation}</b></InfoText>
            <InfoText>{t('career.experience')}: <b>{expirence}</b></InfoText>
            <InfoText>{t('career.employment')}: <b>{formEmployment}</b></InfoText>
            <InfoText>{t('career.workingHours')}: <b>{fullTime}</b></InfoText>
            {salary && <InfoText>{t('career.salary')}: <b>{salary}</b></InfoText>}
            <StyledArrowLink>
                <ArrowLink textWidth={215} link={link}>{t('readMore')}</ArrowLink>
            </StyledArrowLink>
        </Card>
     );
}
 
export default CareerCard;
import * as React from 'react';
import styled from 'styled-components';
import AboutListItem from '../molecules/AboutListItem';
import { FixedObject } from "gatsby-image";

export type AboutListItemType = {
    title: string;
    content: React.ReactNode;
    imageUrl?: FixedObject | FixedObject[];
    number: number;
}

export type AboutListType = AboutListItemType[];

type AboutListProps = {
    title: string;
    list: Array<Omit<AboutListItemType, "number">>;
}

const AboutListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${({ theme }) => theme.paddingDesktopDouble}; 
    padding-top: 166px;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop};
        padding-top: 120px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 95px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
        padding-top: 95px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        padding-top: 45px;
    }
`;

const StyledAboutList = styled.ul`
    display: flex;
    flex-direction: column;
`;

export const AboutListTitle = styled.h2`
    margin-bottom: 70px;
    color: ${({ theme }) => theme.primaryColor}; 
    margin-left: 30px;

    @media  (max-width: 1600px) {
        margin-left: 0;
        margin-bottom: 30px;
    }
`
 


const AboutList: React.FC<AboutListProps> = ({list, title}) => {
    return ( 
        <AboutListWrapper>
            <AboutListTitle>{title}</AboutListTitle>
            <StyledAboutList>
                {list.map((item, index) => <AboutListItem key={index} title={item.title} number={index + 1} imageUrl={item.imageUrl} content={item.content} />)}
            </StyledAboutList>
        </AboutListWrapper>
     );
}
 
export default AboutList;
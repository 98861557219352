import React from 'react';
import styled from 'styled-components';
import { AboutListItemType } from "../organisms/AboutList";
import Img from "gatsby-image";

const AboutListItemNumber = styled.div`
    color: ${({ theme }) => theme.secondaryColor}; 
    font-size: ${({ theme }) => theme.fontSize.h3}; 
    font-weight: 600;
    margin: 0;
    padding-top: 8px;
`

const AboutListItemNumberWrapper = styled.div`
    display: flex;
    margin: 30px 0 0 0;
    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: 16px 0;
    }
`

const AboutListItemNumberLine = styled.div`
    width: 53px;
    height: 2px;
    background-color: ${({ theme }) => theme.secondaryColor}; 
    margin: 30px 24px 0 22px;

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 35px;
        margin: 30px 16px 0 18px;
    }
`

const AboutListItemTitle = styled.h3`
    color: ${({ theme }) => theme.primaryColor}; 
    margin-bottom: 24px;

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin-top: 10px;
    }
`

const AboutListItemWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    width: 100%;

    @media (min-width: 1px) and (max-width: 767.98px) {
        flex-direction: column;
        margin-top: 10px;
    }
`

const AboutListItemContent = styled.div`
    display: flex;
    flex-direction: column;

    img, span {
        margin-bottom: 40px;
        font-family: Museo;
        font-weight: normal;
        font-size: 24px;
        width: 100%;
    }

    @media (max-width: 767.98px) {
        img, span {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        img, span {
            font-size: 20px;
        }
    }
`

const AboutListItemContentWrapper = styled.div`
    display: flex;
    flex-direction: column;

`

const StyledAboutListItem = styled.li`
    display: flex;
    margin-bottom: 100px;
    width: 100%;
    margin-left: 0;

    .aboutList__img {
        display: block;
        min-width: 240px;
        max-width: 240px;
        height: 240px;
        margin-right: 67px;
    }

    @media (max-width: 767.98px) {
        margin-bottom: 40px;
        flex-direction: column;
        align-items: center;
        .aboutList__img {
            margin-right: 0;
            width: 240px;
            height: auto;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        margin-bottom: 55px;

        .aboutList__img {
            margin-right: 0;
            width: 200px;
            height: 200px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        margin-bottom: 55px;

        .aboutList__img {
            margin-right: 16px;
            width: 200px;
            height: 200px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        margin-bottom: 80px;
    }
`;


const AboutListItem: React.FC<AboutListItemType> = ({ title, number, imageUrl, content }) => {
    return (
        <StyledAboutListItem>
            {imageUrl && <Img className="aboutList__img" fixed={imageUrl} alt={title} loading="lazy" />}
            <AboutListItemWrapper>
                <AboutListItemNumberWrapper>
                    <AboutListItemNumber>{number}</AboutListItemNumber>
                    <AboutListItemNumberLine />
                </AboutListItemNumberWrapper>
                <AboutListItemContentWrapper>
                    <AboutListItemTitle>{title}</AboutListItemTitle>
                    <AboutListItemContent>{content}</AboutListItemContent>
                </AboutListItemContentWrapper>
            </AboutListItemWrapper>
        </StyledAboutListItem>
    );
}

export default AboutListItem;
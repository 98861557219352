import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import magnifier from "../../images/magnifier.svg";

type SearchBarProps = {
    handleSearchNews: Function;
}

export const OptionBar = styled.div`
    margin: 0 auto;
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
    }
`; 

const StyledInput = styled.input`
    height: 53px;
    width: 396px;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.lightGreyColor};
    font-size: 18px;
    font-family: Museo Sans;
    font-weight: 300;
    color: ${({ theme }) => theme.greyColor};

    &:focus {
        outline: none;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
    }
`;

const Magnifier = styled.img`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
`;
 
export const SearchBar: React.FC<SearchBarProps> = ({handleSearchNews}) => {
    const {t} = useTranslation();
    
    return ( 
        <OptionBar>    
            <StyledInput 
                onChange={(e) => handleSearchNews(e.target.value)}
                placeholder={t('searchInNews')}
            />
            <Magnifier src={magnifier} alt="lupa"/>
        </OptionBar>
     );
}
import * as React from 'react';
import ArrowForward from '../../images/arrow-forward.svg';
import { Link } from 'gatsby';
import styled from 'styled-components';

type ArrowLinkProps = {
    link: string;
    textWidth: number;
}

const ArrowContent = styled.div<Pick<ArrowLinkProps, "textWidth">>`
    display: flex;
    align-items: center;
    width: ${({textWidth}) => textWidth + "px"};
    overflow-x: hidden;

    @media (min-width: 992px) and (max-width: 1600px) {
        width: ${({textWidth}) => textWidth + "px"};
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: ${({textWidth}) => textWidth > 200 ? textWidth - 21 + "px" : textWidth - 35 + "px"};
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: auto;
        overflow-x: auto;
    }  
`;

const StyledImage = styled.img`
    margin-left: 30px;
    width: 38px;

    @media (min-width: 768px) and (max-width: 991.98px) {
        margin-left: 10px;
    }
`;

const StyledImageHover = styled.img`
    margin: 0 30px 0 0;
    width: 38px;

    @media (min-width: 768px) and (max-width: 991.98px) {
        margin: 0 10px 0 0;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: none;
    }  
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    line-height: 29px;
    font-family: Museo Sans;
    white-space: nowrap;
    font-weight: 300;
    font-size: 18px;
    text-decoration: none;
    font-family: Museo;
    color: ${({ theme }) => theme.blueColor};
    transform: translateX(-68px);
    transition: transform 0.15s linear;

    @media (min-width: 768px) and (max-width: 991.98px) {
        transform: translateX(-38px);
        margin-left: -10px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        transform: translateX(0px);
    }  

    &:hover {
        transform: translateX(0px);

        @media (min-width: 768px) and (max-width: 991.98px) {
            transform: translateX(10px);
    }
    }
`;
 
const ArrowLink: React.FC<ArrowLinkProps> = ({link, textWidth, children}) => {
    return ( 
        <ArrowContent textWidth={textWidth}>
            <StyledLink to={`${link}/`}><StyledImageHover src={ArrowForward} alt="arrow"/>{children}<StyledImage src={ArrowForward} alt="arrow"/></StyledLink>
        </ArrowContent>
     );
}
 
export default ArrowLink;
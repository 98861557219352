import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { AboutListTitle } from '../components/organisms/AboutList';
import CareerCardsGallery from '../components/organisms/CareerCardsGallery';
import HeaderPriceCarrer from '../components/organisms/HeaderPriceCarrer';
import SEO from '../components/seo';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { defaultLanguage } from '../../site-config';
import careerImage from '../images/praca.svg';

export type CareerProps = {
    data: {
		allWpPost: {
			nodes: [
                {
                   career: {
                       careerTitle: string;
                       expirence: string;
                       formEmployment: string;
                       fullTime: string;
                       link: string;
                       officeLocation: string;
                       salary?: string;
                   }
                   uri: string
                   language: {
                       slug: string
                   }
                   translations: [
                        databaseId: number,
                        language: {
                            slug: string
                        }
                    ],
                    databaseId: number
               },
           ]
		}
        allWpPage: {
            edges: [
                {
                    node: {
                        title: string;
                        content: any;
                    }
                }
            ]
        }
	}
}

const StyledAboutList = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.paddingDesktopDouble}; 
    margin-left: 115px;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop};
        padding-top: 120px;
    }

    h2 {
        margin-left: 0;
        margin-bottom: 80px;
        margin-top: 0;
    }

    h3 {
        color: ${({ theme }) => theme.primaryColor}; 
        margin-bottom: 24px;
        counter-increment: section;
        position: relative;
    }

    a {
        font-weight: 700;
        color: ${({ theme }) => theme.secondaryColor}; 
        font-size: 18px;
        margin-top: 40px;
    }

    p {
        margin-bottom: 80px;
        font-weight: normal;
        font-size: 24px;
        width: 100%;
    }

    p:last-of-type {
        margin-bottom: 0px;
        margin-top: 0px;
    }

    h3:before {
        content: counter(section);
        color: ${({ theme }) => theme.secondaryColor}; 
        position: absolute;
        left: -115px
    }

    h3:after {
        content: "";
        background-color: ${({ theme }) => theme.secondaryColor}; 
        position: absolute;
        left: -95px;
        width: 53px;
        height: 2px;
        margin: 30px 24px 0px 22px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 0px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
        padding-top: 0px;
        max-width: 100%;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        padding-top: 0px;
        width: 100%;
        margin-left: 0;

        h3:after {
            left: 20px;
            top: -42px;
        }

        h3:before {
            left: 0px;
            top: -42px;
        }

        p {
            font-size: 18px;
            margin-bottom: 30px;
        }
        h3 {
            margin-top: 60px;
        }
    }
 `;



const Career: React.FC<CareerProps> = ({ data }) => {
    const {t} = useTranslation();
    const {language} = useI18next();
    const listTitle = data.allWpPage.edges[0].node.title;
    const listContent = data.allWpPage.edges[0].node.content;
     
    return (   
        <Layout>
            <SEO title={t('career.title')} description={t('career.description')}/>
            <HeaderPriceCarrer
                title={t('career.title')}
                description={t('career.description')}
                isButton={false}
                isWhiteBackground={false}
                image={careerImage}
            /> 
            {language === defaultLanguage && <CareerCardsGallery data={data}/>}
            <StyledAboutList>
                <AboutListTitle>{listTitle}</AboutListTitle>
                <div dangerouslySetInnerHTML={{ __html: listContent}} />
            </StyledAboutList>
        </Layout>
    );
}
 
export default Career;

export const pageQuery = graphql`
query($language: String!) {
    allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name:  { regex: "/Kariera/" }}}}, language: {slug: {eq: $language}}}
    ) {
    nodes {
            ...Career
            uri
            language {
                slug
            }
            translations {
                databaseId
                language {
                slug
                }
            }
            databaseId
        }
    }
    allWpPage(filter: {slug: {regex: "/proces-rekrutacj/"}, language: {slug: {eq: $language}}}) {
        edges {
            node {
                content
                title
            }
        }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`